export const data1 = [
  {
    title: '1. 普通赔案的服务承诺：',
    value: [
      '（1）7×24小时接听客户服务电话，全年不间断理赔服务；',
      '（2）市区内需要现场查勘的案件30分钟到达，郊区1小时到达；',
      '（3）实行全国通赔服务；',
      '（4）1万元以内的车物案件（责任明确）可安排线上理赔；',
      '（5）30万元以内资料齐全的案件3日内完成结案与支付；',
      '（6）根据保单约定，可协助提供全国救援服务；',
    ],
  },
  {
    title: '2. 重大、疑难赔案的服务承诺：',
    value: [
      '（1）重大、疑难赔案实行专人专岗服务；',
      '（2）对可确认的重大损失赔案，可申请预付赔款；',
      '（3）根据银保监相关指引，对标的及三者损失，可申请实物赔付；',
      '（4）对重大人伤及诉讼案件，可提供法律援助服务；',
    ],
  },
  {
    title: '3. 提供理赔信息自助查询服务。',
  },
];

export const data2 = [
  {
    title: '1. 普通赔案的服务承诺：',
    value: [
      '（1）7×24小时接听客户服务电话，全年不间断理赔服务；',
      '（2）需要现场查勘案件，收到报案后24小时内安排查勘人员前往；',
      '（3）实行全国通赔服务；',
      '（4）1万元以内的医疗案件可安排线上理赔；',
      '（5）20万元以内资料齐全、责任明确的案件3日内完成结案与支付；',
      '（6）根据保单约定，可协助提供保险合同增值服务，如境内外医疗救援服务、重大疾病绿通医疗服务、重大疾病特药等；',
    ],
  },
  {
    title: '2. 重大、疑难赔案的服务承诺：',
    value: [
      '（1）重大、疑难赔案实行专人专岗服务；',
      '（2）对可确认的重大损失赔案，可申请预付赔款；',
      '（3）根据银保监相关指引，对标的及三者损失，可申请实物赔付；',
      '（4）对重大人伤及诉讼案件，可提供法律援助服务；',
    ],
  },
  {
    title: '3. 提供理赔信息自助查询服务。',
  },
];

export const data3 = [
  {
    index: 1,
    name: '京东安联广东分公司',
    emil: '广州市天河区珠江西路5号广州国际金融中心主塔写字楼第34层08-10单元 Unit 08-10, 34/F Main Tower, Guangzhou International Finance Center No. 5 Zhujiang Xi Road, Zhujiang New Town, Guangzhou',
    zipCode: '510623',
    phone: '020-83966788',
  },
  {
    index: 2,
    name: '京东安联北京分公司',
    emil: '北京市北京经济技术开发区科创十一街20号院3号楼11、12层 11/F & 12/F, Building 3, No.20, Kechuang 11th Street, BDA，Beijing',
    zipCode: '100176',
    phone: '010-89128666',
  },
  {
    index: 3,
    name: '京东安联上海分公司',
    emil: '上海市浦东新区银城中路501号上海中心大厦14层1406、1407和1408室 06-0814/F，Unit 1406、1407 & 1408, 14/F Shanghai Tower, No.501 Yincheng Zhong Road, Pudong New District, Shanghai',
    zipCode: '200000',
    phone: '021-20339669',
  },
  {
    index: 4,
    name: '京东安联四川分公司',
    emil: '四川省成都市温江区光华大道三段1588号珠江国际中心29楼2901-2907、2917单元，Unit 2901-2907、2917, 29/F , Zhujiang International Center, No. 1588, section 3, Guanghua Avenue, Wenjiang District, Chengdu',
    zipCode: '611100',
    phone: '028-63105068',
  },
  {
    index: 5,
    name: '京东安联深圳营销服务部',
    emil: '深圳市前海深港合作区南山街道梦海大道5033号前海卓越金融中心3号楼L13-01（深圳市南山区桂湾片区二单元前海卓越金融中心T201-0075（4）101三号楼L13-01），L13-01，Building 3, Qianhai Excellence Financial Center, 5033 Menghai Avenue, Nanshan Street, Qianhai Shenzhen-Hong Kong Cooperation Zone, Shenzhen',
    zipCode: '518000',
    phone: '0755-88286033',
  },
  {
    index: 6,
    name: '京东安联北京朝阳营销服务部',
    emil: '北京朝阳区建国门外大街乙12号双子座大厦西塔11 层 1109B 1109B, 11/F, West Tower, Gemini tower, No. 12, Jianguomenwai Street, Chaoyang District, Beijing',
    zipCode: '130012',
    phone: '010-85400981',
  },
];

export const data4 = [
  {
    title: '1. 线上自助服务平台，为个人客户提供便捷的线上自助保全服务：',
    value: [
      '（1）保单查询路径：官网“京东安联”->保险服务->保单查询；微信“京东安联保险”->自助服务->保单服务->我的保单（需登录）',
      '（2）保单变更申请路径：微信“京东安联保险”->自助服务->保单服务->我的保单（需登录）->保单详情->保单批改',
      '（3）保单验真路径：官网“京东安联”->保险服务->保单验真（含车险和非车险）',
    ],
  },
  {
    title: '2. 线下保全服务：资料齐全无误的基础上，保全工作服务时效承诺：',
    value: [
      '（1）个险：出单/保全：2个工作日，退保退费：5个工作日内；',
      '（2）意健险团险/商业险：出单/保全：3个工作日；',
      '（3）其他重大客服服务：以实际约定为准。',
    ],
  },
];
