import React from 'react';
import './index.scss';
import procedure from 'static/imgs/procedure20240716.jpg';
import img_code3 from 'static/imgs/code_jdalgzh.jpg';

const Complaint: React.FC = () => {
  return (
    <div className='complaint-wrap'>
      <h2 className='title'>投诉处理流程</h2>
      <div className='header_img'>
        <img src={procedure} alt='图片' />
      </div>

      <h3 className='sub_title'>投诉方式</h3>

      <div className='box2'>
        <div className='box2_item'>
          <p className='box2_p1'>京东安联客服热线：</p>
          <p className='box2_p2'>950610</p>
          <p className='box2_p2 box2_mb'>020-85132999</p>
          <p className='box2_p1'>广东正和调解中心：</p>
          <p className='box2_p2'>400-988-8188</p>
          <p className='box2_p1'>
            网址：
            <a
              className='link'
              href='http://www.gd-zhenghe.com.cn'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.gd-zhenghe.com.cn
            </a>
          </p>
        </div>
        <div className='box2_item box_border box_center'>
          <img src={img_code3} alt='icon' className='img_code box2_mb' />
          <p className='box2_p1'>京东安联保险微信公众号</p>
        </div>
        <div className='box2_item box_last'>
          <p className='box2_p1'>官网地址：</p>
          <p className='box2_p1 box2_mb'>
            <a
              className='link'
              href='https://www.jdallianz.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.jdallianz.com
            </a>
          </p>
          <p className='box2_p2'>电子邮箱：</p>
          <p className='box2_p2'>
            <a href='mailto:Contact@allianz.cn' className='link'>
              Contact@allianz.cn
            </a>
          </p>
          <p className='box2_p2' style={{height: '14px'}}></p>
          <p className='box2_p2'>
            <a  
              className='link'
              href='https://www.bkms-system.com/bkwebanon/report/clientInfo?cin=by94UJ&c=-1&language=chi'
              target='_blank'
              rel='noopener noreferrer'>
              Speakup@Allianz（安联集团投诉举报入口）
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Complaint;
